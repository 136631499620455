import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import Dashboard from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import creditcards from './ressources/creditcards';
import benefits from './ressources/benefits';
import advertisers from './ressources/advertisers';
import cardTypes from './ressources/card-types';
import paymentTypes from './ressources/payment-types';
import intendedPurposes from './ressources/intended-purposes';
import insurances from './ressources/insurances';

const i18nProvider = polyglotI18nProvider((locale) => {
    if (locale === 'fr') {
        return import('./i18n/fr').then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

interface AppProps {
    dataProvider: DataProvider;
}

const App = ({ dataProvider }: AppProps) => {
    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource
                name="creditcards"
                {...creditcards}
                options={{ label: 'Creditcards' }}
            />
            <Resource name="benefits" {...benefits} options={{ label: 'Benefits' }} />
            <Resource
                name="advertisers"
                {...advertisers}
                options={{ label: 'Advertisers' }}
            />
            <Resource
                name="card-types"
                {...cardTypes}
                options={{ label: 'Card Types' }}
            />
            <Resource
                name="payment-types"
                {...paymentTypes}
                options={{ label: 'Payment Types' }}
            />
            <Resource
                name="intended-purposes"
                {...intendedPurposes}
                options={{ label: 'Intended Purposes' }}
            />
            <Resource
                name="insurances"
                {...insurances}
                options={{ label: 'Insurances' }}
            />
        </Admin>
    );
};

export default App;
