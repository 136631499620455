import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';

import dataProvider from './dataProvider';
import App from './App';

ReactDOM.render(<App dataProvider={dataProvider} />, document.getElementById('root'));
