import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import config from '../config/config';

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
    const access_token = JSON.parse(localStorage.getItem('access_token') ?? '{}').token;
    const newOptions = options;
    newOptions.headers = new Headers(options.headers || {});
    newOptions.headers.set('Accept', 'application/json');
    newOptions.headers.set('Authorization', `Bearer ${access_token}`);

    return fetchUtils.fetchJson(url, newOptions);
};

const restProvider = simpleRestProvider(`${config.serverUrl}/v1`, httpClient);

export default restProvider;
