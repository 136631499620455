import StarIcon from '@material-ui/icons/Star';

import BenefitList from './BenefitList';
import BenefitEdit from './BenefitEdit';
import BenefitCreate from './BenefitCreate';

export default {
    list: BenefitList,
    edit: BenefitEdit,
    create: BenefitCreate,
    icon: StarIcon
};
