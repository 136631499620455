import SecurityIcon from '@material-ui/icons/Security';

import InsuranceList from './InsuranceList';
import InsuranceEdit from './InsuranceEdit';
import InsuranceCreate from './InsuranceCreate';

export default {
    list: InsuranceList,
    edit: InsuranceEdit,
    create: InsuranceCreate,
    icon: SecurityIcon
};
