const env = process.env.NODE_ENV;
let serverUrl = process.env.REACT_APP_SERVER_URL;
if (env === 'development') {
    serverUrl = 'http://localhost:4000';
}
export default {
    env,
    port: process.env.PORT,
    serverUrl
};
