import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import PaymentTypeList from './PaymentTypeList';
import PaymentTypeEdit from './PaymentTypeEdit';
import PaymentTypeCreate from './PaymentTypeCreate';

export default {
    list: PaymentTypeList,
    edit: PaymentTypeEdit,
    create: PaymentTypeCreate,
    icon: LocalAtmIcon
};
