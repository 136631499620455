import GroupIcon from '@material-ui/icons/Group';

import IntendedPurposeList from './IntendedPurposeList';
import IntendedPurposeEdit from './IntendedPurposeEdit';
import IntendedPurposeCreate from './IntendedPurposeCreate';

export default {
    list: IntendedPurposeList,
    edit: IntendedPurposeEdit,
    create: IntendedPurposeCreate,
    icon: GroupIcon
};
