import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import AdvertiserList from './AdvertiserList';
import AdvertiserEdit from './AdvertiserEdit';
import AdvertiserCreate from './AdvertiserCreate';

export default {
    list: AdvertiserList,
    edit: AdvertiserEdit,
    create: AdvertiserCreate,
    icon: AccountBalanceIcon
};
