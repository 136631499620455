import CategoryIcon from '@material-ui/icons/Category';

import CardTypeList from './CardTypeList';
import CardTypeEdit from './CardTypeEdit';
import CardTypeCreate from './CardTypeCreate';

export default {
    list: CardTypeList,
    edit: CardTypeEdit,
    create: CardTypeCreate,
    icon: CategoryIcon
};
