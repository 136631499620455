import React, { FC } from 'react';
import {
    ArrayInput,
    BooleanInput,
    DateInput,
    Edit,
    EditProps,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    TranslatableInputs
} from 'react-admin';

const CreditcardEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <h2>General</h2>

            <TextInput disabled source="id" style={{ minWidth: '400px' }} />

            <TranslatableInputs
                locales={['en_US', 'de_DE', 'fr_FR', 'es_ES']}
                defaultLocale="en_US"
            >
                <TextInput fullWidth={true} label="Name" source="name" />
            </TranslatableInputs>

            <BooleanInput
                fullWidth={true}
                label="Disabled"
                source="disabled"
                defaultValue={false}
            />

            <h2>Image</h2>

            <TextInput
                fullWidth={true}
                label="URL (Thumbnail)"
                source="image.url_thumbnail"
            />

            <TextInput fullWidth={true} label="URL (Large)" source="image.url_large" />

            <h2>Benefits</h2>

            <ReferenceArrayInput
                fullWidth={true}
                label="All (Displayed in Details View)"
                source="benefits.all"
                reference="benefits"
                perPage={100}
            >
                <SelectArrayInput optionText="text.en_US" />
            </ReferenceArrayInput>

            <ReferenceArrayInput
                fullWidth={true}
                label="Main (Displayed in List View)"
                source="benefits.display"
                reference="benefits"
                perPage={100}
            >
                <SelectArrayInput optionText="text.en_US" />
            </ReferenceArrayInput>

            <h2>Annual Fee</h2>

            <NumberInput
                label="Value"
                source="annual_fee.amount"
                style={{ minWidth: '400px' }}
            />
            <TextInput
                label="Currency"
                source="annual_fee.currency"
                style={{ minWidth: '400px' }}
            />

            <BooleanInput
                fullWidth={true}
                label="First Year Free"
                source="annual_fee.first_year_free"
                defaultValue={false}
            />

            <TranslatableInputs
                locales={['en_US', 'de_DE', 'fr_FR', 'es_ES']}
                defaultLocale="en_US"
            >
                <TextInput fullWidth={true} label="Details" source="annual_fee.details" />
            </TranslatableInputs>

            <h2>Other</h2>

            <NumberInput label="Rating" source="rating" style={{ minWidth: '400px' }} />

            <BooleanInput
                fullWidth={true}
                label="Sponsored (All)"
                source="is_sponsored.all"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Sponsored (Free credit card)"
                source="is_sponsored.6046401887b9c895f7c66d66"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Sponsored (Premium)"
                source="is_sponsored.6046402487b9c826eec66d67"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Sponsored (Collect miles)"
                source="is_sponsored.6046405587b9c87a56c66d68"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Sponsored (Business)"
                source="is_sponsored.604b7a615bdcf583f8c5ad19"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Show on German Version"
                source="display_on_de"
                defaultValue={false}
            />

            <BooleanInput
                fullWidth={true}
                label="Show on Austrian Version"
                source="display_on_at"
                defaultValue={false}
            />

            <NumberInput
                label="Sort Order (All)"
                source="sort_orders.all"
                style={{ minWidth: '400px' }}
                min={0}
                max={10}
                step={1}
            />

            <NumberInput
                label="Sort Order (Free credit card)"
                source="sort_orders.6046401887b9c895f7c66d66"
                style={{ minWidth: '400px' }}
                min={0}
                max={10}
                step={1}
            />

            <NumberInput
                label="Sort Order (Premium)"
                source="sort_orders.6046402487b9c826eec66d67"
                style={{ minWidth: '400px' }}
                min={0}
                max={10}
                step={1}
            />

            <NumberInput
                label="Sort Order (Collect miles)"
                source="sort_orders.6046405587b9c87a56c66d68"
                style={{ minWidth: '400px' }}
                min={0}
                max={10}
                step={1}
            />

            <NumberInput
                label="Sort Order (Business)"
                source="sort_orders.604b7a615bdcf583f8c5ad19"
                style={{ minWidth: '400px' }}
                min={0}
                max={10}
                step={1}
            />

            <h2>Links</h2>

            <TextInput fullWidth={true} label="WordPress" source="links.wordpress" />

            <TextInput
                fullWidth={true}
                label="Affiliate Network"
                source="links.affiliate_network"
            />

            <h2>Features</h2>

            <ReferenceArrayInput
                fullWidth={true}
                label="Card Types"
                source="card_types"
                reference="card-types"
            >
                <SelectArrayInput optionText="title.en_US" />
            </ReferenceArrayInput>

            <ReferenceInput
                fullWidth={true}
                label="Advertiser"
                source="advertiser"
                reference="advertisers"
            >
                <SelectInput optionText="text.en_US" />
            </ReferenceInput>

            <ReferenceInput
                fullWidth={true}
                label="Payment Type"
                source="payment_type"
                reference="payment-types"
            >
                <SelectInput optionText="title.en_US" />
            </ReferenceInput>

            <BooleanInput
                fullWidth={true}
                label="Giro Account"
                source="giro_account"
                defaultValue={false}
            />

            <ReferenceArrayInput
                fullWidth={true}
                label="Intended Purposes"
                source="intended_purposes"
                reference="intended-purposes"
            >
                <SelectArrayInput optionText="title.en_US" />
            </ReferenceArrayInput>

            <NumberInput
                label="Foreign Currency Fee (Percentage)"
                source="foreign_currency_fee"
                style={{ minWidth: '400px' }}
            />

            <NumberInput
                label="Cash Withdrawal Fee (Percentage)"
                source="cash_withdrawal_fee"
                style={{ minWidth: '400px' }}
            />

            <NumberInput
                label="Cash Withdrawal Fee (Minimum Amount)"
                source="cash_withdrawal_min_fee"
                style={{ minWidth: '400px' }}
            />

            <NumberInput
                label="Cash Withdrawal (Minimum Withdrawal Amount)"
                source="cash_withdrawal_min_amount"
                style={{ minWidth: '400px' }}
            />

            <NumberInput
                label="Interest Rate (Percentage)"
                source="interest_rate"
                style={{ minWidth: '400px' }}
            />

            <ReferenceArrayInput
                fullWidth={true}
                label="Insurances"
                source="insurances"
                reference="insurances"
            >
                <SelectArrayInput optionText="title.en_US" />
            </ReferenceArrayInput>

            <h2>Deals</h2>

            <ArrayInput source="deals">
                <SimpleFormIterator>
                    <TextInput
                        fullWidth={true}
                        label="Details (en_US)"
                        source="details.en_US"
                    />
                    <TextInput
                        fullWidth={true}
                        label="Details (de_DE)"
                        source="details.de_DE"
                    />
                    <TextInput
                        fullWidth={true}
                        label="Details (fr_FR)"
                        source="details.fr_FR"
                    />
                    <TextInput
                        fullWidth={true}
                        label="Details (es_ES)"
                        source="details.es_ES"
                    />
                    <DateInput label="Date From" source="dateFrom" />
                    <DateInput label="Date To" source="dateTo" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export default CreditcardEdit;
