import CreditCardIcon from '@material-ui/icons/CreditCard';

import CreditcardList from './CreditcardList';
import CreditcardEdit from './CreditcardEdit';
import CreditcardCreate from './CreditcardCreate';

export default {
    list: CreditcardList,
    edit: CreditcardEdit,
    create: CreditcardCreate,
    icon: CreditCardIcon
};
