import React, { FC } from 'react';
import { Edit, EditProps, SimpleForm, TextInput, TranslatableInputs } from 'react-admin';

const BenefitEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />

            <TextInput source="name" />

            <TextInput source="icon" />

            <TranslatableInputs
                locales={['en_US', 'de_DE', 'fr_FR', 'es_ES']}
                defaultLocale="en_US"
            >
                <TextInput source="text" />
            </TranslatableInputs>
        </SimpleForm>
    </Edit>
);

export default BenefitEdit;
