import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Theme, Box } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps } from 'react-admin';

import { AppState } from '../types';

import creditcards from '../ressources/creditcards';
import benefits from '../ressources/benefits';
import advertisers from '../ressources/advertisers';
import cardTypes from '../ressources/card-types';
import paymentTypes from '../ressources/payment-types';
import intendedPurposes from '../ressources/intended-purposes';
import insurances from '../ressources/insurances';

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
    const translate = useTranslate();

    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <Box mt={1}>
            {' '}
            {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
            <MenuItemLink
                to={`/creditcards`}
                primaryText={'Creditcards'}
                leftIcon={<creditcards.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/benefits`}
                primaryText={'Benefits'}
                leftIcon={<benefits.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/advertisers`}
                primaryText={'Advertisers'}
                leftIcon={<advertisers.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/card-types`}
                primaryText={'Card Types'}
                leftIcon={<cardTypes.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/payment-types`}
                primaryText={'Payment Types'}
                leftIcon={<paymentTypes.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/intended-purposes`}
                primaryText={'Intended Purposes'}
                leftIcon={<intendedPurposes.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/insurances`}
                primaryText={'Insurances'}
                leftIcon={<insurances.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </Box>
    );
};

export default Menu;
