import React, { FC } from 'react';
import { Datagrid, List, ListProps, TextField, EditButton } from 'react-admin';

const PaymentTypeList: FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />

            <TextField label="Title" source="title.en_US" />

            <EditButton basePath="/payment-types" />
        </Datagrid>
    </List>
);

export default PaymentTypeList;
