import React, { FC } from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    TranslatableInputs
} from 'react-admin';

const AdvertiserCreate: FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />

            <TextInput source="icon" />

            <TranslatableInputs
                locales={['en_US', 'de_DE', 'fr_FR', 'es_ES']}
                defaultLocale="en_US"
            >
                <TextInput source="text" />
            </TranslatableInputs>
        </SimpleForm>
    </Create>
);

export default AdvertiserCreate;
