import React, { FC } from 'react';
import {
    Datagrid,
    List,
    ListProps,
    TextField,
    EditButton,
    NumberField,
    BooleanField,
    Filter,
    TextInput
} from 'react-admin';

const ListFilter: FC<any> = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const CreditcardList: FC<ListProps> = (props) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid>
            <TextField source="id" />

            <TextField label="Title" source="name.en_US" />

            <NumberField label="Sort Order (All)" source="sort_orders.all" />

            <BooleanField label="Disabled" source="disabled" />

            <EditButton basePath="/creditcards" />
        </Datagrid>
    </List>
);

export default CreditcardList;
